module.exports = [{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl"],"siteUrl":"https://digital1.alterpage.pl","redirect":false,"pagination":{"translations":{"pl":"strona"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alterpage Agencja Interaktywna","short_name":"Alterpage","start_url":"/","background_color":"#e51f7e","theme_color":"#e51f7e","display":"standalone","icon":"/var/www/digital.alterpage.pl/releases/4/frontend/v1/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"b8ebb91a973dd736be443c74cbb6bfcc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-T8ZRCQG","cookieName":"AP_ADS_AP","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"638124894314865","cookieName":"AP_ADS_AP"},"linkedin":{"trackingId":"4698753","cookieName":"AP_ADS_AP"},"environments":["development","production"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
